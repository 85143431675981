import { Component, OnDestroy, OnInit, Output, EventEmitter, inject, effect  } from '@angular/core';
import { AuthApiService } from 'src/app/api/services/auth/auth-api.service';
import { AuthStore } from 'src/app/api/services/auth/auth.store';
import { AuthService } from 'src/app/api/services/auth/auth.service';
import { MatSlideToggleChange, MatSlideToggleModule} from '@angular/material/slide-toggle';
import { Subject, takeUntil } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgxPermissionsModule } from 'ngx-permissions';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        MatSlideToggleModule,
        MatMenuModule,
        MatIconModule,
        NgxPermissionsModule
    ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  readonly authStore = inject(AuthStore);
  destroy$: Subject<boolean> = new Subject<boolean>();
  userName!: string;
  switchProfile!: string;
  useReportingDB :boolean | null = null;
  orgEntities: any[] = [];
  fontSize = 16;
  private initialCSSVars: any;

  
  constructor(
    public authService: AuthService,
    public auth: AuthApiService, 
  ) {
    effect(() => {
      this.useReportingDB = this.authStore.useReportingDBSignal() ?? false;
    });
    effect(() => {
      if (this.authStore.userSignal && this.authStore.userSignal()) {
        const user = this.authStore.userSignal();
        if (user && typeof user !== 'boolean') {
          this.userName = `${user.firstName} ${user.lastName}`;
        }
      }
    });
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {


    if (localStorage.theme === 'hc') {
      this.highContrast();
    }

    this.initialCSSVars = getCSSCustomPropIndex().reduce((acc: any, cur: any) => {
      acc[`${cur[0]}`] = cur[1];
      return acc;
    }, {});
  }

  reportingDBToggleChange(event: MatSlideToggleChange){
    this.useReportingDB = event.checked;
    this.authService.useReportingDB(this.useReportingDB).subscribe(); 
  }

  
  logout() {
    this.authService.logout();
  }

  resetFontSite() {
    this.setRootSize(16);
  }

  decreaseFontSize() {
    this.setRootSize(this.getRootSize() - 2);
  }

  increaseFontSize() {
    this.setRootSize(this.getRootSize() + 2);
  }

  setRootSize(size: number) {
    this.fontSize = size;
    return (document.documentElement.style.fontSize = size + 'px');
  }

  getRootSize(): number {
    const fontSize = document.documentElement.style?.fontSize?.replace(/\D/g, '') || 16;
    // @ts-ignore
    return parseInt(fontSize, 10);
  }

  resetContrast() {
    localStorage.removeItem('theme');
    if (this.initialCSSVars) {
      this.updateCssVars(this.initialCSSVars);
    }
  }

  highContrast() {
    localStorage.setItem('theme', 'hc');

    const highContrastTheme = {
      '--primary-text': 'var(--primary-text-hc)',
      '--primary': 'var(--primary-text-hc)',
      '--secondary-btn': 'var(--secondary-btn-hc)',
      '--gray-400': 'var(--gray-400-hc)',
    };

    this.updateCssVars(highContrastTheme);
  }

  updateCssVars(theme: { [key: string]: string }) {
    Object.entries(theme).map(([key, value]) => {
      this.updateCssVar(key, value);
    });
  }

  updateCssVar(prop: string, val: string) {
    let root = document.documentElement;
    root.style.setProperty(prop, val);
  }

   
}

// @ts-ignore
const isSameDomain = (styleSheet) => {
  // Internal style blocks won't have an href value
  if (!styleSheet.href) {
    return true;
  }
  return styleSheet.href.indexOf(window.location.origin) === 0;
};

// @ts-ignore
const isStyleRule = (rule) => rule.type === 1;

const getCSSCustomPropIndex = () =>
  // @ts-ignore
  [...document.styleSheets].filter(isSameDomain).reduce(
    (finalArr, sheet) =>
      finalArr.concat(
        [...sheet.cssRules].filter(isStyleRule).reduce((propValArr, rule) => {
          const props = [...rule.style]
            .map((propName) => [propName.trim(), rule.style.getPropertyValue(propName).trim()])
            .filter(([propName]) => propName.indexOf('--') === 0);
          return [...propValArr, ...props];
        }, [])
      ),
    []
  );

