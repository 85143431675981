<div class="p-4">
  <div class="flex justify-center flex-col">
    <div class="flex justify-end close-modal-button-pos">
      <button type="button"  class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="close()">
        <p class="bold mb-0">Close</p>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="justify-center flex">
      <div class="px-12 py-8">
        <div class="text-center px-5 py-3">
            <h2 class="font-bold mb-5">{{data.exportName}}</h2>
        </div>
        <div>
          <p class="text-center">
            Would you like some personal information with your report? Select <strong>Yes</strong> to include personal information or <strong>No</strong> to exclude personal information.
          </p>
          <div [formGroup]="form">
            <mat-form-field class="flex justify-self-center w-full sm:w-1/2">
              <mat-label>Status</mat-label>
              <mat-select formControlName="personalInformation" panelClass="mat-select-mt3">
                <mat-option [value]="true"
                  >Yes 
                </mat-option>
                <mat-option [value]="false"
                >No
              </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="pt-8 flex justify-center">
            <button class="btn-primary medium" type="submit" (click)="export()">
              Export
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>