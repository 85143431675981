import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { appMessageService } from 'src/app/api/services/app-messages/app-message.service';
import { FileUploader, FileUploadModule } from 'ng2-file-upload';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { DomSanitizer } from '@angular/platform-browser';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-dashboard-ad-message-edit',
    templateUrl: './dashboard-ad-message-edit.component.html',
    styleUrls: ['./dashboard-ad-message-edit.component.scss'],
    standalone: true,
    imports: [MatIconModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, FileUploadModule, NgFor, MatCheckboxModule, MatSelectModule, MatOptionModule, MatDatepickerModule]
})
export class DashboardAdMessageEditComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  action: string = 'Add';
  form = this.fb.group({
    title: this.fb.control(null, [Validators.required]),
    imageUrl: this.fb.control(null),
    description: this.fb.control(null, [Validators.required]),
    buttonText  : this.fb.control(null),
    buttonUrl: this.fb.control(null),
    button2Title: this.fb.control(null),
    button2Text: this.fb.control(null),
    button2Url: this.fb.control(null),
    showEntityAdmin: this.fb.control(null),
    showCoopAdmin: this.fb.control(null),
    showOrgLearner: this.fb.control(null),
    showIndividual: this.fb.control(null),
    sitePages: this.fb.control(null),
    startDate: this.fb.control(null, [Validators.required]),
    endDate: this.fb.control(null, [Validators.required]),
  })
  disableFlag: boolean = false;
  maxFileSize = 1024 * 1024;
  requiredImage: boolean = false;
  failImageFlag: boolean = false;
  failSizeFlag: boolean = false;
  failFlag: boolean = false;
  loader: boolean = false;
  public photoUploader: FileUploader = new FileUploader({
    url: '',
    allowedMimeType: ['image/png', 'image/jpeg'],
    maxFileSize: this.maxFileSize,
  });
  responseObj: any;
  states: any;
  showImagePreview: boolean = false;
  selectedFile: any;
  uploadContainer!: string;
  localImageUrl: any;

  constructor(
    private fb: FormBuilder,
    private appMessageService: appMessageService,
    public lookupsService: LookupsService,
    private coursesService: CoursesService,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<DashboardAdMessageEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action?: string, items: any, messageType: string, uploadContainer: string },
  ) {}

    ngOnInit(): void {
      this.lookupsService.getSitePages();

      if(this.data) {
        this.action = this.data.action! == 'edit' ? 'Edit' : 'Add';

        if(this.data.messageType! === 'DashboardAd') {
          this.appMessageService
          .getAppMessage(this.data.messageType, this.data.items.id)
          .subscribe((res: any) => {
            const jsonObject = JSON.parse(res.advertisementJson);
            this.form.patchValue({
              title: jsonObject?.Title,
              description: jsonObject?.Description,
              imageUrl: jsonObject?.ImageUrl,
              buttonText: jsonObject?.ButtonText,
              buttonUrl: jsonObject?.ButtonUrl,
              button2Title: jsonObject?.Button2Title,
              button2Text: jsonObject?.Button2Text,
              button2Url: jsonObject?.Button2Url,
              startDate: res.startDate,
              endDate: res.endDate,
            });
            
            if (res.advertisementDisplayCriteria && res.advertisementDisplayCriteria.length > 0) {
              const criteria = res.advertisementDisplayCriteria[0]; 
              this.form.patchValue({
                  showEntityAdmin: criteria.showEntityAdmin,
                  showCoopAdmin: criteria.showCoopAdmin,
                  showOrgLearner: criteria.showOrgLearner,
                  showIndividual: criteria.showIndividual,
                  sitePages: criteria.advertisementPageId,
              });
          }
          })
        } 
      }

      this.uploadContainer = this.data.uploadContainer;

      this.photoUploader.onAfterAddingFile = (fileItem) => {
        if (this.photoUploader.queue.length > 1) {
          this.photoUploader.queue.splice(0, 1);
        }

        let url = window.URL
          ? window.URL.createObjectURL(fileItem._file)
          : (window as any).webkitURL.createObjectURL(fileItem._file);
        this.localImageUrl = this.sanitizer.bypassSecurityTrustUrl(url);

        this.photoUploader.queue.map((file: any) => {
          this.selectedFile = file.file.rawFile;

          this.loader = true;
          let blobURL: string = '';
          const imageFormData = new FormData();
          imageFormData.append('file', this.selectedFile);
          imageFormData.append('containerName', this.uploadContainer);
          this.appMessageService.postUploadFile(imageFormData).subscribe({
            next: (data: any) => {
              this.loader = false;
              blobURL = data.replace(/"/g, "");
              (this.form.get('imageUrl') as FormControl).patchValue(blobURL);
            },
            error: (error) => {
              this.loader = false;
              console.error('Error uploading file', error);
            },
          });
        });

        this.checkIfImage();
      };

    }

    checkIfImage() {
      const fileType = this.photoUploader.queue[0]?.file.type;
      if (
        fileType === 'image/jpeg' ||
        fileType === 'image/jpg' ||
        fileType === 'image/png' ||
        fileType === 'image/svg+xml'
      ) {
        this.showImagePreview = true;
      } else {
        this.showImagePreview = false;
      }
    }

    onSubmit() {
     const { title, description, imageUrl, buttonText, buttonUrl, button2Title, button2Text, button2Url, startDate, endDate, showEntityAdmin, showCoopAdmin, showOrgLearner, showIndividual, sitePages } = this.form.value;
  
     const AdvertisementDisplayCriteria = {
       AdvertisementId:  this.data.items?.id ?? 0,
       AdvertisementPageId: sitePages  ?? '',
       ShowEntityAdmin: showEntityAdmin  ?? false,
       ShowCoopAdmin: showCoopAdmin  ?? false,
       ShowOrgLearner: showOrgLearner  ?? false,
       ShowIndividual: showIndividual  ?? false,
     }

     const AdvertisementJson = {
        Title: title ?? '',
        Description: description  ?? '',
        ImageUrl: imageUrl,
        ButtonText: buttonText  ?? '',
        ButtonUrl: buttonUrl  ?? '',
        Button2Title: button2Title  ?? '',
        Button2Text: button2Text  ?? '',
        Button2Url: button2Url  ?? '',
     }

     const formValues = {
       id: this.data.items?.id ?? 0,
       AdvertisementTypeId: 1,
       AdvertisementJson: AdvertisementJson,
       AdvertisementDisplayCriteria: AdvertisementDisplayCriteria,
       StartDate: startDate ?? '',
       EndDate: endDate ?? ''
     };

     this.appMessageService
       .upsertAdvertisementMessage(formValues)
       .subscribe({
         next: () => {
          this.dialogRef.close('success');
         },
         error: (err) => {
           console.error('Error uploading data:', err);
           this.loader = false;
         }
       });
    }


    closeModal() {
      this.dialogRef.close();
    }
}



