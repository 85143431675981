import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';

export interface exportData {
  exportName: string
}
@Component({
  selector: 'app-confirm-export-modal',
  standalone: true,
  templateUrl: './confirm-export-modal.component.html',
  styleUrl: './confirm-export-modal.component.scss',
  imports: [
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatOptionModule,
  ],
})
export class ConfirmExportModalComponent implements OnInit {
  form: FormGroup = Object.create(null);
  constructor(
    private dialogRef: MatDialogRef<ConfirmExportModalComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: exportData,
  ) {
    console.log(data);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      personalInformation: this.fb.control(false),
    });
  }

  export() {
    var personalInformation = this.form.get('personalInformation')?.value;
    this.dialogRef.close(!personalInformation);
  }

  close() {
    this.dialogRef.close();
  }
}
