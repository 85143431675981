import { Component, OnInit, OnDestroy, effect, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { tap, catchError, throwError, finalize } from 'rxjs';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { LearningMetricsModalComponent } from 'src/app/shared/modals/learning-metrics/learning-metrics-modal.component';
import { LearnerMetricsStore } from 'src/app/api/services/reports/learner-metrics/learner-metrics.store';
import { LearnerMetricsService } from 'src/app/api/services/reports/learner-metrics/learner-metrics.service';
import { AuthStore } from 'src/app/api/services/auth/auth.store';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { ConfirmExportModalComponent, exportData } from 'src/app/shared/modals/confirm-export-modal/confirm-export-modal.component';

@Component({
    selector: 'app-learner-metrics',
    templateUrl: './learner-metrics.component.html',
    styleUrls: ['./learner-metrics.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent],
})


export class LearnerMetricsComponent implements OnInit, OnDestroy {
  readonly lookupsStore = inject(LookupsStore);
  readonly authStore = inject(AuthStore);
  readonly learnerMetricsStore = inject(LearnerMetricsStore);
  selectedTrainingId: number = 0;
  paramsReq: any;
  defaultSortColumn: string = 'firstName';
  defaultSortDirection: string = 'ASCENDING';
  gridOptions: GridOptions = {
      filterData: [
        { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: 0 },
        { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 0 },
        { name: 'Search', formControlName: 'search', type: 'search' },
        { name: 'Training', formControlName: 'trainingIds', type: 'dropdown', defaultValue: undefined, lookup: this.lookupsStore.trainingsSignal },
        { name: 'Course', formControlName: 'courseIds', type: 'dropdown', defaultValue: undefined, lookup: this.lookupsStore.courseTrainingsSignal },
        { name: 'State', formControlName: 'stateId', type: 'dropdown', lookup: this.lookupsStore.statesSignal },
        { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: this.lookupsStore.learnerStatusSignal },
      ],
      openFilters: true,
      columns: [
        { header: 'First', controlName: 'firstName' },
        { header: 'Last', controlName: 'lastName' },
        { header: 'Account', controlName: 'organizationName' },
        { header: 'Email', controlName: 'email', displayPipe: 'truncate' },
        { header: 'Course', controlName: 'training'},
        { header: 'State', controlName: 'state' },
        { header: 'Added', controlName: 'dateAdded', displayPipe: 'date' },
        { header: 'End', controlName: 'dateCompleted', displayPipe: 'date' },
        { header: 'Purchased', controlName: 'purchaseDate', displayPipe: 'date' },
        { header: 'Price', controlName: 'price', displayPipe: 'currency' },
        { header: 'Status', controlName: 'trainingStatus' },
        { header: 'Certificate', controlName: 'certificateName' },
        { header: 'Action', controlName: 'action', 
          actionColumnitems: [
            { icon: 'edit', buttonAction: 'edit' },
          ]
        }
      ],
      data: new MatTableDataSource<any>(),
      totalNumberOfItems: 0,
      allowExportCSV: true,
      addButtonName: undefined,
      disableflag: false,
      isLoading: false,
      displaySearch: true,
    };

  constructor(
    private dialog: MatDialog,
    private learnerMetricsService: LearnerMetricsService,
    private lookupsService: LookupsService,
    private snackBar: MatSnackBar,
  ) {
      // Set default training to Child Abuse Training
      effect(() => {
        if (this.lookupsStore.trainingsSignal().length > 0) {
          var filteredTraining = this.lookupsStore.trainingsSignal().filter((training) => training.name === 'Child Abuse');
          if (filteredTraining.length > 0) {
            var id = filteredTraining[0]?.value;
            this.selectedTrainingId = id;
            var trainingFilter = this.gridOptions.filterData.find((filter) => filter.formControlName === 'trainingIds');
            trainingFilter!.defaultValue = id;
            if (this.paramsReq) {
              this.gridOptions.fetchData = true;
              this.lookupsService.getCourseTrainings(this.selectedTrainingId);
            }
          }
        }
      });

      var gridDatasource: any[] = [];
      effect(() => {
        gridDatasource = [];
        const all = this.learnerMetricsStore.learnerMetricsSignal() ?? {};
        if (all.totalNumberOfItems != null) {
          const { rows, totalNumberOfItems } = all;
          rows?.forEach((row: any) => {
            const datasource = gridDatasource;
            datasource.push(row);
            gridDatasource = datasource;
          });
          this.gridOptions.data.data = gridDatasource;
          this.gridOptions.totalNumberOfItems = totalNumberOfItems;
        }
      });
  }

  ngOnInit(): void {
    this.lookupsService.getLearnerStatus();
    this.lookupsService.getStates();
    this.lookupsService.getCourses();
    this.lookupsService.getTrainings();
    this.gridOptions.fetchData = true;
  }

  ngOnDestroy(): void {
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true;

    var useReportingDB = this.authStore.useReportingDBSignal() ?? false;

    const { search, startDate, endDate, stateId, trainingIds, courseIds, status, orgId, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: startDate,
      ToDate: endDate,
      StateId: stateId ? stateId : '',
      OrgId: orgId ? orgId : '',
      TrainingIds: trainingIds ? trainingIds : [],
      CourseIds: courseIds ? courseIds : [],
      Status: status ? status : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
      UseReportingDB: useReportingDB ? useReportingDB : false,
    };

    this.paramsReq = params;
    this.learnerMetricsService.getAll(params).pipe(
      // Reset the cursor after fetching the data
      finalize(() => this.gridOptions.isLoading = false),
    )
    .subscribe();

  }

  onTrainingChange() {
    // Reset the Course dropdown
    var courseId = this.gridOptions.filterData.find((filter) => filter.formControlName === 'courseIds');
    courseId!.defaultValue = null;
    this.lookupsService.getCourseTrainings(this.selectedTrainingId);
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'firstName':
          activeSort = 'firstName';
          break;
        case sort.active === 'lastName':
          activeSort = 'lastName';
          break;
        case sort.active === 'organizationName':
          activeSort = 'orgName';
          break;
        case sort.active === 'email':
          activeSort = 'email';
          break;
        case sort.active === 'state':
          activeSort = 'state';
          break;
        case sort.active === 'training':
          activeSort = 'training';
          break;
        case sort.active === 'course':
          activeSort = 'courseName';
          break;
        case sort.active === 'version':
          activeSort = 'version';
          break;
        case sort.active === 'dateAdded':
          activeSort = 'dateAdded';
          break;
        case sort.active === 'dateCompleted':
          activeSort = 'dateCompleted';
          break;
          case sort.active === 'purchaseDate':
            activeSort = 'purchaseDate';
            break;
          case sort.active === 'price':
            activeSort = 'amount';
            break;
        case sort.active === 'trainingStatus':
          activeSort = 'trainingStatus';
          break;
        case sort.active === 'totalTime':
          activeSort = 'totalTime';
          break;
        case sort.active === 'certificateName':
          activeSort = 'certificateName';
          break;
        case sort.active === 'shared':
          activeSort = 'shared';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  openModal(row: any) {
      const dialogRef = this.dialog.open(LearningMetricsModalComponent, {
        panelClass: 'full-screen-modal-medium-mobile-panel-class',
        backdropClass: 'dialog-bg-darkgrey',
        maxWidth: '100vw',
        data: {
          items: row,
          gridParams: this.paramsReq,
        },
      });
  }

  exportCsv() {
        const dialogRef = this.dialog.open(ConfirmExportModalComponent, {
          panelClass: 'full-screen-modal-medium-mobile-panel-class',
          backdropClass: 'dialog-bg-darkgrey',
          data: <exportData>{
            exportName: 'Learner Reports',
          },
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response === null || response === undefined) {
            return;
          }
          this.paramsReq.NoPersonalInformation = response;
    this.learnerMetricsService
      .exportCSV(this.paramsReq)
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe({
        next: () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Report will be sent to your email' }})
          this.gridOptions.disableflag = false;
        },
        error: () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong, please try Again' }})
          this.gridOptions.disableflag = false;
        },
      });
    });
  }

  formChanges(form: any) {
    if (this.selectedTrainingId !== form.trainingIds) {
      this.selectedTrainingId = form.trainingIds;
      this.onTrainingChange();
    }
  }

  clearFilters() {
    var filteredTraining = this.lookupsStore.trainingsSignal().filter((training) => training.name === 'Child Abuse');

    if (filteredTraining.length > 0) {
      var id = filteredTraining[0]?.value;
      this.selectedTrainingId = id;
      var trainingFilter = this.gridOptions.filterData.find((filter) => filter.formControlName === 'trainingIds');
      trainingFilter!.defaultValue = id;
      if (this.paramsReq) {
        this.paramsReq.TrainingIds = [id];
        // Now populate the Course dropdown
        this.lookupsService.getCourseTrainings(this.selectedTrainingId);
      }
    }
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      this.exportCsv();
    } else if (event.action === 'formChanges') {
      this.formChanges(event.data);
    } else if (event.action === 'clearFilters') {
      this.clearFilters();
    } // 'edit' is specific to this component 
    else if (event.action === 'edit') {
      this.openModal(event.data);
    }
  }
}
