<div class="p-4">
    <div class="flex justify-center flex-col">
        <div class="flex justify-end close-modal-button-pos">
          <button type="button"  class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="close()">
            <p class="bold mb-0">Close</p>
            <mat-icon>close</mat-icon>
          </button>
        </div>
    
        <div class="admin-modal-body">
            <div class="px-12 py-8">
                <div class="text-center px-5 py-3">
                    <h2 class="font-bold mb-5">Reset Password</h2>
                </div>
                <p>
                    Enter the email address and we'll send the user a link to reset their password.
                </p>
                <form [formGroup]="form" class="pt-3">
                    <div class="reset-psw-max-w">
                        <mat-form-field class="w-full">
                        <mat-label>Email</mat-label>
                        <input type="text" matInput formControlName="email" />
                        <mat-error *ngIf="form.controls['email']?.hasError('required') && form.controls['email']?.touched">Required
                        </mat-error>
                        <mat-error *ngIf="form.controls['email']?.errors?.email && form.controls['email']?.touched">You must include a
                            valid email.
                        </mat-error>
                        <mat-error *ngIf="form.controls['email']?.errors?.message">{{
                            form.controls['email'].getError('message')
                            }}</mat-error>
                        </mat-form-field>
                    </div>
                </form>
                <div class="text-center">
                    <button class="btn-primary large" [disabled]="disableFlag" (click)="onSubmit()">
                        Send Email
                    </button>
                </div>
            </div>
        </div>  
    </div>
</div>
