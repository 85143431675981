import { Component, effect, inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { catchError, Subscription, tap, throwError } from 'rxjs';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { OrgAllStore } from 'src/app/api/services/organizations/all/org-all.store';
import { OrgAllService } from 'src/app/api/services/organizations/all/org-all.service';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { ConfirmExportModalComponent, exportData } from 'src/app/shared/modals/confirm-export-modal/confirm-export-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-organization-all',
    templateUrl: './organization-all.component.html',
    styleUrls: ['./organization-all.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent],
})
export class OrganizationAllComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  readonly orgAllStore = inject(OrgAllStore);
  paramsReq: any;
  defaultSortColumn: string = 'orgName';
  defaultSortDirection: string = 'ASCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: -365 },
      { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
      {
        name: 'Industry',
        formControlName: 'industryId',
        type: 'dropdown',
        defaultValue: undefined,
        lookup: this.lookupsStore?.industriesSignal,
      },
      { name: 'State', formControlName: 'stateId', type: 'dropdown', lookup: this.lookupsStore.statesSignal },
      { name: 'Plan', formControlName: 'planId', type: 'dropdown', lookup: this.lookupsStore?.pricingPlansSignal},
      { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: this.lookupsStore?.entityStatusSignal },
    ],
    openFilters: true,
    columns: [
      { header: 'Create Date', controlName: 'createDate', displayPipe: 'date'},
      { header: 'Organization Name', controlName: 'organizationName' },
      { header: 'Org.Admin', controlName: 'orgAdmin', displayPipe: 'truncate' },
      { header: 'Org.Industry', controlName: 'orgIndustry' },
      { header: 'State', controlName: 'state' },
      { header: 'Trial End', controlName: 'trialEnded',  displayPipe: 'date' },
      { header: 'Plan', controlName: 'planName' },
      { header: 'Plan Limit Seats/Certs', controlName: 'planUnits' },
      { header: 'Used Seats/Certs', controlName: 'usedPlanUnits', conditionalClass: { calculateClass: (value: string) => { return value  ? "text-warn" : "";} , classColumn: 'overLimit'}},
      { header: 'PAYG Certs', controlName: 'payAsYouGo' },
      { header: 'Status', controlName: 'status' },
      { header: 'Edit', controlName: 'action', actionColumnitems: [{ icon: 'edit', buttonAction: 'edit' }] },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: true,
    disableflag: false,
    isLoading: false,
    displaySearch: true,
  };
  formValueChangesSub!: Subscription;
  sortOrder: any;
  pageSizeOptions: Array<number> = [20, 40, 60, 100];
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private orgAllService: OrgAllService,
    private lookupsService: LookupsService,
    private snackBar: MatSnackBar
  ) {
    var gridDatasource: any[] = [];
    effect(() => {
      const orgAll = this.orgAllStore.orgAllSignal() ?? {};
      gridDatasource = [];
      if (orgAll.totalNumberOfItems != null) {
        const { rows, totalNumberOfItems } = orgAll;
        rows?.forEach((row: any) => {
          const datasource = gridDatasource;
          datasource.push(row);
          gridDatasource = datasource;
        });
        this.gridOptions.data.data = gridDatasource;
        this.gridOptions.totalNumberOfItems = totalNumberOfItems;
        this.gridOptions.isLoading = false;
      }
    });
  }

  ngOnInit(): void {
    this.lookupsService.getEntityStatus();
    this.lookupsService.getStates();
    this.lookupsService.getIndustries();
    this.lookupsService.getPricingPlans();
    this.gridOptions.fetchData = true;
  }
  
  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true
    const { search, stateId, planId, numberOfSeats, numberOfActiveUsers, trialEnded, industryId, startDate, endDate, status, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      StateId: stateId ? stateId : '',
      FromDate: startDate,
      ToDate: endDate,
      PlanId: planId ? planId : '',
      NumberOfSeats: numberOfSeats,
      NumberOfActiveUsers: numberOfActiveUsers,
      TrialEnded: trialEnded,
      IndustryId: industryId ? industryId : '',
      Status: status ? status : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };

    this.paramsReq = params;
    this.orgAllService.getAll(params).subscribe();
  }

  displayFn(search: any): string {
    return search && search.name ? search.name : search?.length > 0 ? search : '';
  }

  goToEdit(row: any) {
    this.router.navigate(['/organizations/settings', row?.id], { state: { name: row.organizationName } }).then();
  }

  exportCsv() {
        const dialogRef = this.dialog.open(ConfirmExportModalComponent, {
          panelClass: 'full-screen-modal-medium-mobile-panel-class',
          backdropClass: 'dialog-bg-darkgrey',
          data: <exportData>{
            exportName: 'All Organizations',
          },
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response === null || response === undefined) {
            return;
          }
    const paramsReq = {
      SearchTerm: this.paramsReq?.SearchTerm ? this.paramsReq?.SearchTerm : '',
      StateId: this.paramsReq?.StateId ? this.paramsReq?.StateId : '',
      IndustryId: this.paramsReq?.IndustryId ? this.paramsReq?.IndustryId : '',
      Status: this.paramsReq?.Status ? this.paramsReq?.Status : '',
      NoPersonalInformation: response,
    };
    this.orgAllService
      .export(paramsReq)
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe({
        next: () => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Success, message: 'Report will be sent to your email' },
          });
        },
        error: () => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Error, message: 'Something went wrong, please try Again' },
          });
        },
      });
    });
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) {
      switch (true) {
        case sort.active === 'organizationName':
          activeSort = 'orgName';
          break;
        case sort.active === 'state':
          activeSort = 'state';
          break;
        case sort.active === 'orgIndustry':
          activeSort = 'orgIndustry';
          break;
        case sort.active === 'orgAdmin':
          activeSort = 'orgAdmin';
          break;
        case sort.active === 'planName':
          activeSort = 'planName';
          break;
        case sort.active === 'numberOfSeats':
          activeSort = 'numberOfSeats';
          break;
        case sort.active === 'usedPlanUnits':
          activeSort = 'usedPlanUnits';
          break;
        case sort.active === 'planUnits':
          activeSort = 'planUnits';
          break;
        case sort.active === 'payAsYouGo':
          activeSort = 'payAsYouGo';
          break;
        case sort.active === 'trialEnded':
          activeSort = 'trialEnded';
          break;
        case sort.active === 'createDate':
          activeSort = 'createDate';
          break;
        case sort.active === 'status':
          activeSort = 'status';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      this.exportCsv();
    } else if (event.action === 'formChanges') {
      // form changes
    } else if (event.action === 'clearFilters') {
      // clear filters
    } else if (event.action === 'addButton') {
      // add button
    }
    // 'edit' is specific to this component
    else if (event.action === 'edit') {
      this.goToEdit(event.data);
    }
  }
}